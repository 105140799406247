import { Component, Vue } from 'vue-property-decorator'

//components
import EContent from '@/components/esic/eContent/EContent.vue';
import PrivateContent from '@/components/privateContent/PrivateContent.vue';

//molds
import { Form } from '@/components/wrappers/molds/Form';
import { Process } from '@/store/modules/process/process';
import { TableOptions, WolfApiData } from '@/components/wrappers/molds/TableOptions';

//utils
import { convertToParameterQuery } from '@/utils';

//services
import { Alert } from '@/services/notification';
import { ProcessService } from '@/services/processService';

//Vuex
const npProcess = 'process';
import { Action, Getter } from 'vuex-class';

//wrappers
import { 
	Box,
  WHr,
	WForm, 
	Loader,
	WTable,
  WButton,
	SizedBox,
	Container,
	WSubtitle,
} from '@/components/wrappers';

@Component({
	components: {
		WHr,
		Box,
		WForm,
		WTable,
		Loader,
		WButton,
		EContent,
		SizedBox,
		Container,
		WSubtitle,
		PrivateContent,
	}
})
export default class SearchProcess extends Vue {

	@Getter('error', { namespace: npProcess }) error!: any;

	//TODO deprecated
	isSearch = false
	
	//TODO deprecated
	result!: WolfApiData
	
	isLoading = true
	wolfApiData!: any

	processSelect!: Process

	//TODO deprecated
	alert = new Alert(this.$store)

	perPage = 20
	showTable = true
	paramQuery!: string

	processService = new ProcessService(this.$store)

	mounted(): void {
		document.title = 'Pesquisar Pedidos'
		this.searchProcess("")
	}

	changePage(page: number): void {
		let _page = `_page=${page}`
		if(this.paramQuery != "?"){
			_page = '&'+_page
		}
		this.searchProcess(`${this.paramQuery}${_page}`)
	}

	async dispatchForm(input: any): Promise<void> {
		input = this.processService.convertNumYear(input)
		this.paramQuery = convertToParameterQuery(input)		
		this.searchProcess(this.paramQuery)
	}

	async searchProcess(query: string): Promise<void> {
		this.isLoading = true
		this.paramQuery = query
		
		await this.processService.dispatchSearchProcess(`${query}&_limit=${this.perPage}`).then((response) => {
			if(response){				
				this.wolfApiData = response;

				if(this.wolfApiData.list.length == 0){
					this.alert.warning("Nenhum processo encontrado")
				}else{
					window.scroll({
						top: 700,
						behavior: 'smooth'
					});
				}
			}
		})
		this.isLoading = false
	}

	tableSelected(process: Process): void {
		this.showTable = false
		this.processSelect = process
		// this.setParamQeury(process)
		this.processService.setProcess(process)
		this.$router.push("/process/"+process.idProcess)
	}

	get process(): Process {
		return this.processSelect
	}

	setParamQeury(process: Process): void {
		this.$router.push({ path: 'search', query: { process: process.idProcess }})
	}

	startTable(): void {
		this.showTable = true
	}
	// não é parametro query devido a conversão num/ano
	get form(): Form {
		return {
			flex: false,
			indent: true,
			convertDate: true,
			grid: [
				[
					{
						fieldName: "numYear",
						type: "text",
						label: "Número/Ano",
						placeholder:"1/2019",
						maxlength: 30,
					},
					{
						fieldName: "subject",
						type: "text",
						placeholder:"Assunto",
						label: "Assunto",
						maxlength: 30,
						like: true
					},
					{
						fieldName: "dateTimeOpened",
						type: "date",
						label: "Data de Abertura",
						maxlength: 10
					},
				],
				[
					{
						fieldName: "userOrigin",
						type: "text",
						label: "Usuário origem",
						placeholder:"Usuário origem",
						maxlength: 50,
						like: true
					},
					{
						fieldName: "groupOrigin",
						type: "text",
						label: "Grupo Origem",
						placeholder:"Grupo Origem",
						maxlength: 30,
						like: true
					},
					{
						fieldName: "groupDestiny",
						type: "text",
						label: "Grupo Destino",
						placeholder:"Grupo Destino",
						maxlength: 100,
						like: true
					},
				],
				[
					{
						fieldName: "details",
						type: "text",
						label: "Detalhes",
						placeholder:"Detalhes",
						maxlength: 100,
						like: true
					},
					{
						fieldName: "status",
						label: "Status",
						type: "select",
						maxlength: 11,
						value: "",
						options:[
							{
								label: "Selecione ...",
								value: ""
							},
							{
								label: "A Importar Arquivado",
								value: "0"
							},
							{
								label: "Arquivado",
								value: "1"
							},
							{
								label: "Encaminhado",
								value: "2"
							},
							{
								label: "Recebido",
								value: "3"
							},
							{
								label: "Atrasado",
								value: "4"
							},
							{
								label: "Finalizado",
								value: "5"
							},
						]
					},
				],
			]
		}
	}

	//############# Table ##############
	get	table(): TableOptions | null {
		if(this.isLoading){
			return null
		}
		return {	
			paginated: true,
			perPage: this.perPage,
			wolfApiData: this.wolfApiData,
			columns: [
				{
					field: 'num',
					label: 'Número',
					sortable: true,
				},
				{
					field: 'year',
					label: 'Ano',
					sortable: true
				},
				{
					field: 'subject',
					label: 'Assunto',
					sortable: true
				},
				{
					field: 'dateTimeOpened',
					label: 'Data de Abertura',
					sortable: true
				},
				// {
				// 	field: 'userOrigin',
				// 	label: 'Usuário de origem',
				// 	sortable: true
				// },
				// {
				// 	field: 'groupOrigin',
				// 	label: 'Grupo de Origem',
				// 	sortable: true
				// },
				{
					field: 'groupDestiny',
					label: 'Grupo de Destino',
					sortable: true
				},
				{
					field: 'details',
					label: 'Detalhes',
					sortable: true
				},
				{
					field: 'status',
					label: 'Status',
					sortable: true
				},
			]
		}
	}


}
